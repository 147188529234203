import http from "../http-common"; 

class AppPreferenceService {
  getAllAppPreferences(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/appPreference/appPreferences`, searchDTO);
  }

  get(appPreferenceId) {
    return this.getRequest(`/appPreference/${appPreferenceId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/appPreference?field=${matchData}`, null);
  }

  addAppPreference(data) {
    return http.post("/appPreference/addAppPreference", data);
  }

  update(data) {
  	return http.post("/appPreference/updateAppPreference", data);
  }
  
  uploadImage(data,appPreferenceId) {
  	return http.postForm("/appPreference/uploadImage/"+appPreferenceId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new AppPreferenceService();
