import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AppStates from  '@/pages/AppStates.vue';
import AppStateDetail from  '@/pages/AppStateDetail.vue';
import Tremors from  '@/pages/Tremors.vue';
import TremorDetail from  '@/pages/TremorDetail.vue';
import TremorTrends from  '@/pages/TremorTrends.vue';
import TremorTrendDetail from  '@/pages/TremorTrendDetail.vue';
import Medications from  '@/pages/Medications.vue';
import MedicationDetail from  '@/pages/MedicationDetail.vue';
import Dashboards from  '@/pages/Dashboards.vue';
import DashboardDetail from  '@/pages/DashboardDetail.vue';
import ContextSenseAnalysiss from  '@/pages/ContextSenseAnalysiss.vue';
import ContextSenseAnalysisDetail from  '@/pages/ContextSenseAnalysisDetail.vue';
import RecentTremors from  '@/pages/RecentTremors.vue';
import RecentTremorDetail from  '@/pages/RecentTremorDetail.vue';
import MedicationTrackings from  '@/pages/MedicationTrackings.vue';
import MedicationTrackingDetail from  '@/pages/MedicationTrackingDetail.vue';
import Insightss from  '@/pages/Insightss.vue';
import InsightsDetail from  '@/pages/InsightsDetail.vue';
import WeeklyReports from  '@/pages/WeeklyReports.vue';
import WeeklyReportDetail from  '@/pages/WeeklyReportDetail.vue';
import Timelines from  '@/pages/Timelines.vue';
import TimelineDetail from  '@/pages/TimelineDetail.vue';
import MedEffectivenesss from  '@/pages/MedEffectivenesss.vue';
import MedEffectivenessDetail from  '@/pages/MedEffectivenessDetail.vue';
import SuggestedActions from  '@/pages/SuggestedActions.vue';
import SuggestedActionDetail from  '@/pages/SuggestedActionDetail.vue';
import Settingss from  '@/pages/Settingss.vue';
import SettingsDetail from  '@/pages/SettingsDetail.vue';
import AppPreferences from  '@/pages/AppPreferences.vue';
import AppPreferenceDetail from  '@/pages/AppPreferenceDetail.vue';
import AdaptiveFeatures from  '@/pages/AdaptiveFeatures.vue';
import AdaptiveFeatureDetail from  '@/pages/AdaptiveFeatureDetail.vue';
import DataPrivacys from  '@/pages/DataPrivacys.vue';
import DataPrivacyDetail from  '@/pages/DataPrivacyDetail.vue';
import ExportDatas from  '@/pages/ExportDatas.vue';
import ExportDataDetail from  '@/pages/ExportDataDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
			redirect: '/appStates',
																		  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () => import("../views/PricingView.vue"),
  },
  {
    path: "/arts-gallery",
    name: "ArtsGalleryView",
    component: () => import("../views/ArtsGalleryView.vue"),
  },
  {
    path: "/checkout/:id",
    name: "CheckoutView",
    component: () => import("../views/CheckoutView.vue"),
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckoutView",
    component: () => import("../views/StripeCheckoutView.vue"),
  },
	{
		path: '/appStates',
		name: 'AppStates',
		layout: DefaultLayout,
		component: AppStates,
	},
	{
	    path: '/appState/:appStateId', 
	    name: 'AppStateDetail',
		layout: DefaultLayout,
	    component: AppStateDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/tremors',
		name: 'Tremors',
		layout: DefaultLayout,
		component: Tremors,
	},
	{
	    path: '/tremor/:tremorId', 
	    name: 'TremorDetail',
		layout: DefaultLayout,
	    component: TremorDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/tremorTrends',
		name: 'TremorTrends',
		layout: DefaultLayout,
		component: TremorTrends,
	},
	{
	    path: '/tremorTrend/:tremorTrendId', 
	    name: 'TremorTrendDetail',
		layout: DefaultLayout,
	    component: TremorTrendDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/medications',
		name: 'Medications',
		layout: DefaultLayout,
		component: Medications,
	},
	{
	    path: '/medication/:medicationId', 
	    name: 'MedicationDetail',
		layout: DefaultLayout,
	    component: MedicationDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/dashboards',
		name: 'Dashboards',
		layout: DefaultLayout,
		component: Dashboards,
	},
	{
	    path: '/dashboard/:dashboardId', 
	    name: 'DashboardDetail',
		layout: DefaultLayout,
	    component: DashboardDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/contextSenseAnalysiss',
		name: 'ContextSenseAnalysiss',
		layout: DefaultLayout,
		component: ContextSenseAnalysiss,
	},
	{
	    path: '/contextSenseAnalysis/:contextSenseAnalysisId', 
	    name: 'ContextSenseAnalysisDetail',
		layout: DefaultLayout,
	    component: ContextSenseAnalysisDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/recentTremors',
		name: 'RecentTremors',
		layout: DefaultLayout,
		component: RecentTremors,
	},
	{
	    path: '/recentTremor/:recentTremorId', 
	    name: 'RecentTremorDetail',
		layout: DefaultLayout,
	    component: RecentTremorDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/medicationTrackings',
		name: 'MedicationTrackings',
		layout: DefaultLayout,
		component: MedicationTrackings,
	},
	{
	    path: '/medicationTracking/:medicationTrackingId', 
	    name: 'MedicationTrackingDetail',
		layout: DefaultLayout,
	    component: MedicationTrackingDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/insightss',
		name: 'Insightss',
		layout: DefaultLayout,
		component: Insightss,
	},
	{
	    path: '/insights/:insightsId', 
	    name: 'InsightsDetail',
		layout: DefaultLayout,
	    component: InsightsDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/weeklyReports',
		name: 'WeeklyReports',
		layout: DefaultLayout,
		component: WeeklyReports,
	},
	{
	    path: '/weeklyReport/:weeklyReportId', 
	    name: 'WeeklyReportDetail',
		layout: DefaultLayout,
	    component: WeeklyReportDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/timelines',
		name: 'Timelines',
		layout: DefaultLayout,
		component: Timelines,
	},
	{
	    path: '/timeline/:timelineId', 
	    name: 'TimelineDetail',
		layout: DefaultLayout,
	    component: TimelineDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/medEffectivenesss',
		name: 'MedEffectivenesss',
		layout: DefaultLayout,
		component: MedEffectivenesss,
	},
	{
	    path: '/medEffectiveness/:medEffectivenessId', 
	    name: 'MedEffectivenessDetail',
		layout: DefaultLayout,
	    component: MedEffectivenessDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/suggestedActions',
		name: 'SuggestedActions',
		layout: DefaultLayout,
		component: SuggestedActions,
	},
	{
	    path: '/suggestedAction/:suggestedActionId', 
	    name: 'SuggestedActionDetail',
		layout: DefaultLayout,
	    component: SuggestedActionDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/settingss',
		name: 'Settingss',
		layout: DefaultLayout,
		component: Settingss,
	},
	{
	    path: '/settings/:settingsId', 
	    name: 'SettingsDetail',
		layout: DefaultLayout,
	    component: SettingsDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/appPreferences',
		name: 'AppPreferences',
		layout: DefaultLayout,
		component: AppPreferences,
	},
	{
	    path: '/appPreference/:appPreferenceId', 
	    name: 'AppPreferenceDetail',
		layout: DefaultLayout,
	    component: AppPreferenceDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/adaptiveFeatures',
		name: 'AdaptiveFeatures',
		layout: DefaultLayout,
		component: AdaptiveFeatures,
	},
	{
	    path: '/adaptiveFeature/:adaptiveFeatureId', 
	    name: 'AdaptiveFeatureDetail',
		layout: DefaultLayout,
	    component: AdaptiveFeatureDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/dataPrivacys',
		name: 'DataPrivacys',
		layout: DefaultLayout,
		component: DataPrivacys,
	},
	{
	    path: '/dataPrivacy/:dataPrivacyId', 
	    name: 'DataPrivacyDetail',
		layout: DefaultLayout,
	    component: DataPrivacyDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/exportDatas',
		name: 'ExportDatas',
		layout: DefaultLayout,
		component: ExportDatas,
	},
	{
	    path: '/exportData/:exportDataId', 
	    name: 'ExportDataDetail',
		layout: DefaultLayout,
	    component: ExportDataDetail,
	    props: true // Pass route params as props to the component
  	},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
