<template>
  <a-layout style="min-height: 100vh">
    <!-- Header -->
    <a-layout-header style="background: #fff; padding: 0">
      <app-header />
    </a-layout-header>

    <!-- Main layout with sider and content -->
    <a-layout>
      <!-- Collapsible Sider with Controlled Open Keys for Nested Menu -->
      <a-layout-sider width="200px" collapsible style="background: #fff">
        <a-menu
          mode="inline"
          :openKeys="openKeys"
          @openChange="onOpenChange"
          style="height: 100%; border-right: 0"
        >
<!--          <a-sub-menu key="sub1" title="Menu 1">
            <a-menu-item key="1">Option 1</a-menu-item>
            <a-menu-item key="2">Option 2</a-menu-item>
            <a-sub-menu key="sub1-1" title="Nested Menu">
              <a-menu-item key="3">Option 3</a-menu-item>
              <a-menu-item key="4">Option 4</a-menu-item>
            </a-sub-menu>
          </a-sub-menu>
          <a-sub-menu key="sub2" title="Menu 2">
            <a-menu-item key="5">Option 5</a-menu-item>
            <a-menu-item key="6">Option 6</a-menu-item>
          </a-sub-menu>
-->>

	 
			
				<a-menu-item>
					<router-link to="/appStates">
						<span class="label">App States</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/tremors">
						<span class="label">Tremors</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/tremorTrends">
						<span class="label">Tremor Trends</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/medications">
						<span class="label">Medications</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/dashboards">
						<span class="label">Dashboards</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/contextSenseAnalysiss">
						<span class="label">Context Sense Analysiss</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/recentTremors">
						<span class="label">Recent Tremors</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/medicationTrackings">
						<span class="label">Medication Trackings</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/insightss">
						<span class="label">Insightss</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/weeklyReports">
						<span class="label">Weekly Reports</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/timelines">
						<span class="label">Timelines</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/medEffectivenesss">
						<span class="label">Med Effectivenesss</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/suggestedActions">
						<span class="label">Suggested Actions</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/settingss">
						<span class="label">Settingss</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/appPreferences">
						<span class="label">App Preferences</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/adaptiveFeatures">
						<span class="label">Adaptive Features</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/dataPrivacys">
						<span class="label">Data Privacys</span>
					</router-link>
				</a-menu-item>
		
				<a-menu-item>
					<router-link to="/exportDatas">
						<span class="label">Export Datas</span>
					</router-link>
				</a-menu-item>
		
	
        </a-menu>
      </a-layout-sider>
      
      <!-- Content area -->
      <a-layout-content style="padding: 16px">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { ref } from "vue";
import AppHeader from "@/components/AppHeader.vue";

export default {
  name: "DefaultLayout",
  components: {
    AppHeader,
  },
  setup() {
    const openKeys = ref(["sub1"]); // default open submenu
    const onOpenChange = (keys) => {
      // Open only the last clicked submenu, for single open behavior
      openKeys.value = keys.length ? [keys[keys.length - 1]] : [];
    };

    return {
      openKeys,
      onOpenChange,
    };
  },
};
</script>
<style>
.ant-layout {
    margin-top: 20px;
}
</style>

