import http from "../http-common"; 

class RecentTremorService {
  getAllRecentTremors(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/recentTremor/recentTremors`, searchDTO);
  }

  get(recentTremorId) {
    return this.getRequest(`/recentTremor/${recentTremorId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/recentTremor?field=${matchData}`, null);
  }

  addRecentTremor(data) {
    return http.post("/recentTremor/addRecentTremor", data);
  }

  update(data) {
  	return http.post("/recentTremor/updateRecentTremor", data);
  }
  
  uploadImage(data,recentTremorId) {
  	return http.postForm("/recentTremor/uploadImage/"+recentTremorId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new RecentTremorService();
