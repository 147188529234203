import http from "../http-common"; 

class TimelineService {
  getAllTimelines(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/timeline/timelines`, searchDTO);
  }

  get(timelineId) {
    return this.getRequest(`/timeline/${timelineId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/timeline?field=${matchData}`, null);
  }

  addTimeline(data) {
    return http.post("/timeline/addTimeline", data);
  }

  update(data) {
  	return http.post("/timeline/updateTimeline", data);
  }
  
  uploadImage(data,timelineId) {
  	return http.postForm("/timeline/uploadImage/"+timelineId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new TimelineService();
