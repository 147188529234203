<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h4 class="card-title">Table on Plain Background</h4>
            <p class="category">Here is a subtitle for this table</p>
          </template>-->
          <div class="table-full-width text-left">
            <appState-table
            v-if="appStates && appStates.length > 0"
				:title="table1.title"
				:sub-title="table1.subTitle"
				:appStates="appStates"
				:totalElements="totalElements"
				:page="page"
				:columns="table1.columns"
 				@get-all-app-states="getAllAppStates"
             >

            </appState-table>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { Card } from "@/components/Card";

import AppStateTable from "@/components/AppStateTable";
import AppStateService from "../services/AppStateService";

const tableColumns = [];
const tableData = [
];

export default {
  components: {
    Card,
    AppStateTable,
  },
  data() {
    return {
      appStates: [],
	  totalElements: 0,
      page: 0,
      searchQuery: '',     
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
  methods: {
    async getAllAppStates(sortBy='appStateId',sortOrder='true',page="0",size="50") {
      try {
        try {
			const searchDTO = {
				sortBy: sortBy, 
				sortOrder: sortOrder, 
				searchQuery: this.searchQuery,
				page: page, 
				size: size
			};
	        let response = await AppStateService.getAllAppStates(searchDTO);
	        if (!response.data.empty) {
		        if (response.data.appStates.length) {
					this.appStates = response.data.appStates;
				}
      			this.totalElements = response.data.totalElements;
      			this.page = response.data.page;
	        }
        } catch (error) {
          console.error("Error fetching appStates:", error);
        }
        
      } catch (error) {
        console.error("Error fetching appState details:", error);
      }
    },
  },
  mounted() {
    this.getAllAppStates();
  },
  created() {
    this.$root.$on('searchQueryForAppStatesChanged', (searchQuery) => {
    	this.searchQuery = searchQuery;
    	this.getAllAppStates();
    })
  }
};
</script>
<style></style>
