import http from "../http-common"; 

class WeeklyReportService {
  getAllWeeklyReports(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/weeklyReport/weeklyReports`, searchDTO);
  }

  get(weeklyReportId) {
    return this.getRequest(`/weeklyReport/${weeklyReportId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/weeklyReport?field=${matchData}`, null);
  }

  addWeeklyReport(data) {
    return http.post("/weeklyReport/addWeeklyReport", data);
  }

  update(data) {
  	return http.post("/weeklyReport/updateWeeklyReport", data);
  }
  
  uploadImage(data,weeklyReportId) {
  	return http.postForm("/weeklyReport/uploadImage/"+weeklyReportId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new WeeklyReportService();
