import http from "../http-common"; 

class MedEffectivenessService {
  getAllMedEffectivenesss(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/medEffectiveness/medEffectivenesss`, searchDTO);
  }

  get(medEffectivenessId) {
    return this.getRequest(`/medEffectiveness/${medEffectivenessId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/medEffectiveness?field=${matchData}`, null);
  }

  addMedEffectiveness(data) {
    return http.post("/medEffectiveness/addMedEffectiveness", data);
  }

  update(data) {
  	return http.post("/medEffectiveness/updateMedEffectiveness", data);
  }
  
  uploadImage(data,medEffectivenessId) {
  	return http.postForm("/medEffectiveness/uploadImage/"+medEffectivenessId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new MedEffectivenessService();
