import http from "../http-common"; 

class InsightsService {
  getAllInsightss(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/insights/insightss`, searchDTO);
  }

  get(insightsId) {
    return this.getRequest(`/insights/${insightsId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/insights?field=${matchData}`, null);
  }

  addInsights(data) {
    return http.post("/insights/addInsights", data);
  }

  update(data) {
  	return http.post("/insights/updateInsights", data);
  }
  
  uploadImage(data,insightsId) {
  	return http.postForm("/insights/uploadImage/"+insightsId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new InsightsService();
