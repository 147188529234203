import http from "../http-common"; 

class ContextSenseAnalysisService {
  getAllContextSenseAnalysiss(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/contextSenseAnalysis/contextSenseAnalysiss`, searchDTO);
  }

  get(contextSenseAnalysisId) {
    return this.getRequest(`/contextSenseAnalysis/${contextSenseAnalysisId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/contextSenseAnalysis?field=${matchData}`, null);
  }

  addContextSenseAnalysis(data) {
    return http.post("/contextSenseAnalysis/addContextSenseAnalysis", data);
  }

  update(data) {
  	return http.post("/contextSenseAnalysis/updateContextSenseAnalysis", data);
  }
  
  uploadImage(data,contextSenseAnalysisId) {
  	return http.postForm("/contextSenseAnalysis/uploadImage/"+contextSenseAnalysisId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new ContextSenseAnalysisService();
