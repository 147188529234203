import http from "../http-common"; 

class AppStateService {
  getAllAppStates(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/appState/appStates`, searchDTO);
  }

  get(appStateId) {
    return this.getRequest(`/appState/${appStateId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/appState?field=${matchData}`, null);
  }

  addAppState(data) {
    return http.post("/appState/addAppState", data);
  }

  update(data) {
  	return http.post("/appState/updateAppState", data);
  }
  
  uploadImage(data,appStateId) {
  	return http.postForm("/appState/uploadImage/"+appStateId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new AppStateService();
